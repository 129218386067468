*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "poppins", sans-serif;
}

.body1{
    overflow-x: hidden;
}

/* .container1{
    position: relative;
    width: 100%;
    height: 100%;
} */
.start{
    position: relative;
    padding: 10px;
    width: 100%;
    height: 100%;
}
.navigation{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color:#E6F8DB;
    transition: 0.5s;
    /* overflow: hidden; */
    overflow-x : hidden;
}

/* .navigation.active{
    width: 60px;
} */
.navigation ul{
    position: absolute;
    top: 0;
    left: 0;
    width: 250px;
    padding: 0px;
}
.navigation ul li{
    position: relative;
    width: 100%;
    list-style: none;
}
.navigation ul li:hover{
    background: #73be44;
}

.navigation ul li .active{
    background: #73be44;
}

.navigation ul li:nth-child(1){
    margin-bottom: 5px;
}
.navigation ul li:nth-child(1):hover{
    background: transparent;
}

.navigation ul li a{
    position: relative;
    display: block;
    width: 100%;
    display: flex;
    text-decoration: none;
    color: #000;
}
.navigation ul li a:hover{
    color: #fff;
}

.navigation ul li a.active{
    color: #fff;
}
.navigation ul li .icon{
    position: relative;
    display: block;
    min-width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
}

.navigation ul li .icon .fa{
    color: #fff;
    font-size: 24px;
}
.navigation ul li .title{
    position: relative;
    display: block;
    padding: 0 10px;
    height: 45px;
    line-height: 45px ;
    white-space: nowrap;
}

/* end for side bar */

.main{
    position:absolute;
    width: calc(100% - 250px);
    left: 250px;
    min-height: 100vh;
    background: #f5f5f5;
    transition: 0.5s;
}
.main.active{
    width: calc(100% - 52px);
    left: 52px;
}
.main .topbar{
    width: 100%;
    background: #fff;
    height:43px;
    /* padding: 0 10px; */
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
}
.toggle{
    position: relative;
    width: 43px;
    height: 43px;
    cursor: pointer;
}
.toggle::before{
    content: "\f0c9";
    font-family:fontawesome ;
    position: absolute;
    width: 100%;
    height: 100%;
    line-height: 43px;
    font-size: 24px;
    text-align: center;
    color: black;
}
.search{
    position: relative;
    width: 400px;
    margin: 0 10px;
}
.search label {
    position: relative;
    width: 100%;
}
.search label input {
    width: 100%;
    height: 40px;
    border-radius: 40px;
    padding: 5px 20px;
    padding-left: 35px; ;
    outline: none;
    border: 1px solid rgba(0,0,0,0.2);
}

.search label .fa-solid {
    position: absolute;
    left: 15px;
    top: 4px;
}

.user{
    position: relative;
    min-width: 50px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
}
.user img{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@media(max-width: 992px){
    .navigation{
        left: -250px;
    }
    .navigation.active{
        left: 0;
        width: 250px;
    }
    .main{
        width: 100%;
        left: 0;
    }
    .main.active{
        width: calc(100% - 250px);
        left: 250px;
    } 
}
@media(max-width: 480px){
    .navigation{
        width: 100%;
        left:-100%;
        z-index: 1000;
    }
    .navigation.active{
        width: 100%;
        left:0;
    }
    .toggle.active{
        z-index: 100000;
        right: 0;
        left: initial;
    }
    .toggle.active::before{
        color: #000;
    }
    
}